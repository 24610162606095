import { Paginator } from "../../components/ContactsPage/Paginator/Paginator"
import { VisitReportModal } from "../../components/VisitReportPage/VisitReportModal"
import { Button } from "../../components/VisitReportPage/Button"
import { SwitchButton } from "../../components/VisitReportPage/SwitchButton"
import { VisitReport } from "../../components/VisitReportPage/VisitReport"
import plus from "../../assets/fonts/plus.svg"
import { AddingReport } from "../../components/VisitReportPage/VisitReportModal/childrens/AddingReport"
import { AddressError } from "../../components/VisitReportPage/VisitReportModal/childrens/AddressError"
import { LeftTown } from "../../components/VisitReportPage/VisitReportModal/childrens/LeftTown"
import { UpdateContact } from "../../components/VisitReportPage/VisitReportModal/childrens/UpdateContact"
import { FormikType, Visit } from "../../types/VisitReports/types"
import { Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { NewContact } from "../../components/VisitReportPage/VisitReportModal/childrens/NewContact"
import { ScheduledVisit } from "../../components/VisitReportPage/ScheduledVisit"
import { Household } from "../../types/global/types"
import "./styles.scss";
import { useUrlQueryParams } from "../../hooks/useUrlQueryParams";
import { EmptyStatePastVisitsTab } from '../../components/VisitReportPage/EmptyStatePastVisitsTab';
import { SuccessModal } from '../../components/base/SuccessModal';
import { ErrorModal } from '../../components/base/ErrorModal';
import { UserContext } from '../../contexts/UserContext';
import { UserPermissionsEnum } from '../../enums/userPermissionsEnum';
import { useTranslation } from "../../hooks/useTranslation"

type Props = {
  formik: FormikType
  visits: Visit[]
  scheduledVisits: Visit[]
  statuses: string[]
  reactions: {}
  addressError: string[]
  household: Household
  salutations: string[]
  addressesTypes: string[],
  paginationVisits: {
    currentPage: number,
    setCurrentPage: Dispatch<SetStateAction<number>>
    totalItemsCount: number,
    pageSize: number
  },
  successModalProps: {
    isVisible: boolean,
    setIsVisible: () => void;
    title: string
  },
  errorModalProps: {
    isVisible: boolean,
    setIsVisible: () => void;
    title: string
  }
}

export const VisitReportPageLayout: FC<Props> = ({
  formik,
  visits,
  scheduledVisits,
  statuses,
  reactions,
  addressError,
  household,
  salutations,
  paginationVisits,
  successModalProps,
  errorModalProps,
  addressesTypes,
}) => {
  const { p } = useTranslation('contact_info');
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false)
  const [openAddressError, setOpenAddressError] = useState(false)
  const [openLeftTown, setOpenLeftTown] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openNewContact, setOpenNewContact] = useState(false)
  const [step, setStep] = useState<string | undefined>("Adding report")
  const [remindedDate, setRemindedDate] = useState("")
  // const [remindedDate, setRemindedDate] = useState(dayjs().format('DD.MM.YYYY'))
  const [stepCount, setStepCount] = useState(0)
  const [isRemind, setIsRemind] = useState(false)
  const [visitsType, setVisitsType] = useState("Past visits")
  const { getUrlQueryParam } = useUrlQueryParams();

  useEffect(() => {
    const tab = getUrlQueryParam("tab");
    if (tab === 'duch') {
      setOpen(true);
    }
  }, [])

  useEffect(() => {
    if (stepCount < 5) {
      const children = childrens.find(
        (elem: { step: string; number: number; component: ReactNode }) =>
          elem.number === stepCount
      )
      setStep(children?.step)
    }
  }, [stepCount]);

  const childrens = [
    {
      step: "Adding report",
      number: 0,
      component: (
        <AddingReport
          formik={formik}
          statuses={statuses}
          reactions={reactions}
          isRemind={isRemind}
          setRemindedDate={setRemindedDate}
          remindedDate={remindedDate}
          setIsRemind={setIsRemind}
        />
      ),
    },
    {
      step: "Address error",
      number: 1,
      component: (
        <AddressError
          formik={formik}
          addressError={addressError}
          openAddressError={openAddressError}
          setOpenAddressError={setOpenAddressError}
        />
      ),
    },
    {
      step: "Left town",
      number: 2,
      component: (
        <LeftTown
          formik={formik}
          addressesTypes={addressesTypes}
          openLeftTown={openLeftTown}
          setOpenLeftTown={setOpenLeftTown}
          showCheckbox
        />
      ),
    },
    {
      step: "Update contact",
      number: 3,
      component: (
        <UpdateContact
          formik={formik}
          household={household}
          salutations={salutations}
          addressesTypes={addressesTypes}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
        />
      ),
    },
    {
      step: "New contact",
      number: 4,
      component: (
        <NewContact
          formik={formik}
          salutations={salutations}
          addressesTypes={addressesTypes}
          openNewContact={openNewContact}
          setOpenNewContact={setOpenNewContact}
        />
      ),
    },
  ]

  const children = childrens.find(
    (elem: { step: string; number: number; component: ReactNode }) =>
      elem.step === step
  )

  const handleSaveReport = async () => {
    if (
      formik.errors['status'] ||
      formik.errors['my_categories'] ||
      formik.errors['reaction_scale'] ||
      !Object.values(formik.touched).length) {
      return formik.handleSubmit()
    }
    if (isRemind) {
      await formik.setFieldValue(
        "remind_me_at_formatted",
        remindedDate + " 12:00 AM"
      )
    }
    await formik.handleSubmit();
    setOpen(false);
  }

  const handleNextStep = async () => {
    if (stepCount === 0) {
      if (
        formik.errors['status'] ||
        formik.errors['my_categories'] ||
        formik.errors['reaction_scale'] ||
        !Object.values(formik.touched).length) {
        return formik.handleSubmit()
      }
      if (isRemind) {
        formik.setFieldValue(
          "remind_me_at_formatted",
          remindedDate + " 12:00 AM"
        )
      }
      return setStepCount(prevState => prevState + 1)
    }

    if (stepCount === 1) {
      if (
        (!formik.touched['address_error_options'] ||
          formik.errors['address_error_options']) &&
        openAddressError) {
        return formik.handleSubmit()
      }
      return setStepCount(prevState => prevState + 1)
    }

    if (stepCount === 2) {
      if ((formik.errors.new_address && Object.values(formik.errors.new_address).length) &&
        openLeftTown) {
        // @ts-ignore
        formik.setTouched({ ...formik.touched, new_address: { type: true, place_id: true } })
        return formik.validateForm(formik.values);
      }
      return setStepCount(prevState => prevState + 1)
    }

    if (stepCount === 3) {
      if ((
        formik.errors?.updated_persons &&
        Object.values(formik.errors?.updated_persons as unknown as { [key: string]: string }[])?.length
      ) && openUpdate) {
        return formik.handleSubmit()
      }
      return setStepCount(prevState => prevState + 1);
    }

    if (stepCount === 4) {
      if ((
        formik.errors?.new_persons &&
        Object.values(formik.errors?.new_persons as unknown as { [key: string]: string }[])?.length
      ) && openNewContact) {
        return formik.handleSubmit();
      }
      await formik.setErrors({});
      await formik.handleSubmit();
      return setOpen(prevState => !prevState)
    }
  }
  const allChildrens = childrens.map(item => item.component);

  return (
    <div className="visit-report-page">
      <div className="visit-report-page__buttons">
        <SwitchButton visitsType={visitsType} setVisitsType={setVisitsType} />
        <div style={{ minWidth: "98px" }}>
          <Button
            title={p('add_visit_report')}
            // img={plus}
            // isDisabled={!household.scheduledVisitExists || !user?.permissions[UserPermissionsEnum.createVisits]}
            isDisabled={false}
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      {visitsType === "Past visits" ? (
        <>
          {!visits.length && (
            <EmptyStatePastVisitsTab />
          )}
          {!!visits.length && (
            <>
              {visits.map((elem: any, index: any) => {
                return (
                  <div key={index} className="visit-report-page__report">
                    <VisitReport
                      remind={elem.remind_me}
                      reaction={elem.reaction_name}
                      remindedDate={elem.remind_me_at_formatted}
                      plannedDate={elem.date}
                      status={elem.status}
                      visitNote={elem.visit_details}
                      followUpDetails={elem.follow_up_details}
                    />
                  </div>
                )
              })}
              <div className='visit-report-page__paginator'>
                <Paginator {...paginationVisits} />
              </div>
            </>
          )}
        </>
      ) : (
        <ScheduledVisit
          household={household}
          scheduledVisits={scheduledVisits}
        />
      )}
      <VisitReportModal
        handleSaveReport={handleSaveReport}
        open={open}
        stepCount={stepCount}
        setOpen={(bool: boolean) => {
          formik.resetForm();
          setOpen(bool)
        }}
        setStep={setStep}
        handleNextStep={handleNextStep}
        setStepCount={setStepCount}
      >
        {/*{children?.component}*/}
        {allChildrens}
      </VisitReportModal>
      <SuccessModal {...successModalProps} />
      <ErrorModal {...errorModalProps} />
    </div>
  )
}
